import React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { axiosPrivate } from "../api/axios";

import "./Outlets.css";

import { useEffect, useState } from "react";

import { useRole } from "../context/UserContext";

import { useParams } from "react-router-dom";

import axios from "../api/axios";

import EditNoteIcon from "@mui/icons-material/EditNote";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";

import CastIcon from "@mui/icons-material/Cast";
import CollectionsIcon from "@mui/icons-material/Collections";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import { RWebShare } from "react-web-share";

import { IoIosShareAlt } from "react-icons/io";

import CircularProgress from "@mui/material/CircularProgress";

import "./Singleevent.css";

import { BASE_URL } from "../constants";

const Singleevent = () => {
  const { event_id } = useParams();
  const { role } = useRole();
  const navigate = useNavigate();

  const id = localStorage.getItem("userId");

  const userName = localStorage.getItem("userName");

  const [eventData, setEventData] = useState({});

  const [userData, setUserData] = useState({});

  const [admins, setAdmins] = useState([]);

  const [isLogedIn, setIsLogedIn] = useState(false);

  const [isEventReserved, setIsEventReserved] = useState(false);

  const [open, setOpen] = useState(false);

  const [introVideo, setIntroVideo] = useState();

  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);

  const handleloadingClose = () => setLoading(false);

  const options = ["1", "2", "3", "4", "5", "6", "7", "8"]; //Guestlist Option

  const [form, setForm] = useState({
    reservation_id: "",
    user_id: "",
    user_email_id: "",
    event_id: "",
    event_name: "",
    outlet_id: "", //based on single outlet selected
    outlet_name: "", //based on single outlet selected
    outlet_email: "",
    event_start_time: null, // timestamp
    event_end_time: null, // timestamp
    event_location_map: "", // outlet location map
    event_address: "", //outlet address
    booking_guestlist: 2,
    customer_category: "GROUP",
    poster_url: null, // Add poster_url field
  });


  // Guestlist Start
  const [openGuestList, setOpenGuestList] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [dataFetched, setDataFetched] = useState(false);
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    // console.log("index-->", index)
    setOpenGuestList(false);
    setForm({ ...form, booking_guestlist: index + 1 });
  };

  const handleToggle = () => {
    setOpenGuestList((prevOpen) => !prevOpen);
  };

  const handleCloseGuestList = (event) => {
    console.log("Click away detected");
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenGuestList(false);
  };
  // Guestlist End

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];


  //Event Reservation Check : Reservation|Reserved
  const checkEventReservation = async () => {
    const userid = localStorage.getItem("userId");
    try {
      const response = await axiosPrivate.get(
        `/reservations/check_reservation/${event_id}/${userid}`
      );

      if (response.status === 200) {
        setIsEventReserved(true);
      }
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    const userid = localStorage.getItem("userId");

    let isMounted = true;

    if (userid) {
      setIsLogedIn(true);
    }

    const getUserData = async () => {
      try {
        const response = await axios.get(`/user/${userid}`);
        let user_data = response.data;

        setUserData(user_data);

        /*  setForm({
            ...form,
            user_id: userid,
            user_email_id: response.data.email,
          });*/
        setForm((prevForm) => ({
          ...prevForm,
          user_id: userid,
          user_email_id: response.data.email,
        }));
      } catch (err) {
        console.error(err);
      }
    };

    const getAdmins = async () => {
      try {
        const response = await axios.get(`/user/admins`, {
          // signal: controller.signal
        });

        setAdmins(response.data);

      } catch (err) {
        console.error(err);
      }
    };

    if (userid && !dataFetched) {
      if (isMounted) {
        getUserData();
        checkEventReservation();
        getAdmins();
        setDataFetched(true)
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    let isMounted = true;

    const getEventData = async () => {
      try {
        const response = await axios.get(`/events/${event_id}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        //   console.log("event id", event_id)
        let event_data = response.data;
        isMounted && setEventData(event_data);

        setIntroVideo(event_data.intro_video);
        //  console.log("poster url", event_data.poster_url)
        setForm((prevForm) => ({
          ...prevForm,
          event_id: event_data.event_id,
          event_name: event_data.event_name,
          outlet_id: event_data.outlet_id,
          outlet_name: event_data.outlet_name,
          event_start_time: event_data.event_start_time,
          event_end_time: event_data.event_end_time,
          event_location_map: event_data.map,
          event_address: event_data.event_address,
          poster_url: event_data.poster_url,
        }));

        //   console.log("start time->", eventData.start_time)
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };
    console.log("--->", eventData.event_location_map)
    isMounted && getEventData();

    return () => {
      isMounted = false;


    };
  }, [event_id, isEventReserved]);

  useEffect(() => {
    let isMounted = true;

    const getOutletData = async () => {
      try {
        const response = await axios.get(`/outlets/${form.outlet_id}`);
        let outlet_email = response.data.email;

        /*  setForm({
            ...form,
            outlet_email: outlet_email,
          });*/
        if (isMounted) {
          setForm((prevForm) => ({
            ...prevForm,
            outlet_email: outlet_email,
          }));
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (form.outlet_id) {
      getOutletData();
    }
    const date = new Date(); // Local time
    const utcDate = date.toISOString();
    console.log("______>", utcDate);
    //isMounted && getOutletData();
    console.log("")
    return () => {
      isMounted = false;
      console.log("ismounted--->", isMounted)
    };

  }, [form.outlet_id]);

  const handleReservation = () => {
    if (isLogedIn) {
      let access = true; // Initialize access to true by default

      // Loop through the artist lineup array
      eventData.artist_lineup.forEach((artist) => {
        if (id === artist.id) {
          // If the ID matches, set access to false and break out of the loop
          access = false;
          return; // Exit the loop early since we found a match
        }
      });

      if (id === eventData.outlet_id || !access) {
        alert("You Cannot Reserve Your Own Events");
      } else {
        setOpen(true);
      }
      // alert("Reservation Successful");
    } else {
      alert("Please Login to Use this Option");
      navigate("/login");
    }
  };

  const handleTicketBook = () => {
    if (isLogedIn) {
      let access = true; // Initialize access to true by default

      // Loop through the artist lineup array
      eventData.artist_lineup.forEach((artist) => {
        if (id === artist.id) {
          // If the ID matches, set access to false and break out of the loop
          access = false;
          return; // Exit the loop early since we found a match
        }
      });

      if (id === eventData.outlet_id || !access) {
        alert("You Cannot Book Your Own Events");
      } else {
        window.open(eventData?.ticket_link, "_blank");
      }
    } else {
      alert("Please Login to Use this Option");
      navigate("/login");
    }
  };

  const sendmessges = async () => {
    try {
      await axiosPrivate.post(
        `/inbox`,
        {
          message_receivers: JSON.stringify([
            ...admins
              .filter((admin) => admin.role === "superadmin")
              .map((admin) => ({
                id: admin.userId,
                email_id: admin.email,
                name: admin.first_name + " " + admin.last_name,
              })),

            {
              id: form.outlet_id,
              email_id: form.outlet_email,
              name: form.outlet_name,
            },
          ]),
          subject: "CUSTOMER BOOKING",
          message: `To ${form.outlet_name} \n
              New Event has been successfully reserved by ${userName}.\n\n Recorded Details:\n
                          Event Name: ${form.event_name}
                          Email: ${form.user_email_id}
                          Customer Category: ${form.customer_category}
                          Total Members: ${form.booking_guestlist}
                          Date: ${new Date(eventData.event_start_time).getDate() +
            "/" +
            (new Date(eventData.event_start_time).getMonth() +
              1) +
            "/" +
            new Date(eventData.event_start_time).getFullYear()
            } 
                          Start Time:  ${convertToLocalTime(
              new Date(eventData.event_start_time)
            )}
                         
                          `,

          category: "Outlet",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error sending message:", error.message);
      alert("An error occurred while sending message");
    }
  };

  // Customer category for reservation
  const handleChange = (e) => {
    console.log("radio button", e.target.value)
    console.log("target name", e.target.name)

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log("form-->", form)
  };

  const reserveEvent = async () => {
    try {
      const formData = new FormData();

      for (const key in form) {
        formData.append(key, form[key]);
      }

      const response = await axiosPrivate.post(`/reservations`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setOpen(false);
        sendReservationMessage();

      } else {
        alert("Failed to Reserve Event");
      }
    } catch (error) {
      console.error("Error Reserving Event:", error.message);
      alert("An error occurred while Reserving Event");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  function convertToLocalTime(timeString) {
    // Convert the string to a JavaScript Date object in GMT
    var /*gmtDate*/utcDate = new Date(timeString);
    console.log("timestring-->", timeString);
    // console.log("---->", istDate)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("Your local system timezone is:", timeZone);
    // Calculate the IST offset (5 hours 30 minutes ahead of GMT)
    var istOffset = 5.5 * 60 * 60000; // 5.5 hours in milliseconds

    // Convert to IST by adding the offset
    /* var istDate = new Date(gmtDate.getTime()+ istOffset );*/utcDate.setTime(utcDate.getTime() /*+ istOffset*/);

    console.log("utc date data-->", utcDate);
    var istDate = new Date(utcDate.getTime() - istOffset);
    console.log("ist date data-->", istDate);
    // console.log("ist date-->", istDate)
    // Extract hours and minutes
    var hours = istDate.getHours();
    var minutes = istDate.getMinutes();

    // Determine AM or PM
    var amOrPm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 12-hour clock format, so convert 0 to 12
    //  console.log("hr-->", hours)
    // Format time
    var formattedTime =
      hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + amOrPm;

    return formattedTime;
  }




  const sendReservationMessage = async () => {
    console.log("customer category", form.customer_category)
    console.log("booking list", form.booking_guestlist)
    console.log("reservation-->", form)
    setLoading(true);
    const response = await axios.post("/user/sendReservationMessage", {
      email: form.user_email_id,
      outlet_email: form.outlet_email,
      customer_name: userName,
      outlet_name: eventData.outlet_name,
      event_name: eventData.event_name,
      start_date:
        new Date(eventData.event_start_time).getDate() +
        "/" +
        (new Date(eventData.event_start_time).getMonth() + 1) +
        "/" +
        new Date(eventData.event_start_time).getFullYear(),
      start_time: convertToLocalTime(new Date(eventData.event_start_time)),
      reservation_category: form.customer_category,
      total_members: form.booking_guestlist,
    });
    if (response.status === 200) {
      sendmessges();
      handleloadingClose();
      handleClose();
      alert("Event Reserved successfully");
      checkEventReservation();
    } else {
      handleloadingClose();
      alert("We are sorry for the inconvenience, please try again later.");
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <div className="layout mb-5">
      <div className="outletparent d-flex flex-wrap mb-5">
        <div className="eachartistmainsection">
          <div className="mainsection text-light d-flex flex-column mb-5">


            <div className="videosection">
              {introVideo ? (
                <video
                  autoPlay
                  muted
                  loop
                  className=" object-cover   z-[-1] background-video2"
                  style={{ width: "100%", height: "100%" }}
                  key="new-video"
                >
                  <source src={`${BASE_URL}${introVideo}`} type="video/mp4" />
                </video>
              ) : (
                <p>No video available.</p>
              )}
            </div>


            <div className="container-fluid">
              <div className="row">

                <div className="col-lg-7 col-md-12 col-sm-12  col-sm-12  pe-lg-4">
                  <div className="row blocks-content mt-10">
                    <div className="col-lg-6 col-md-5 col-sm-12 d-flex align-items-center">
                      <div className="" >
                        {" "}
                        <div>
                          <img src={`${BASE_URL}${eventData?.poster_url}`}
                            alt="imgs"
                            className="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-12">
                      <div className="">
                        <h1 className="text-center h5 mb-4 text-light font-semibold mt-2"
                          style={{ fontSize: "17px" }}>
                          DETAILS
                        </h1>

                        <div>
                          {eventData.outlet_id === id &&
                            new Date(eventData.event_start_time) > new Date() && (
                              <div onClick={() =>
                                navigate("/update-event", {
                                  state: {
                                    eventId: eventData.event_id,
                                    userRole: userData.role,
                                  },
                                })
                              }
                                className="flex border rounded justify-content-around align-items-center cursor-pointer"
                                style={{
                                  height: "auto",
                                  width: "85px",
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                }} >
                                <button style={{ color: "white" }}>EDIT</button>
                                <EditNoteIcon style={{ color: "white" }} />
                              </div>
                            )}

                          <div className="card-body flex flex-column justify-content-center detailsform">
                            <div className="d-flex align-items-center mb-3 "
                              style={{ width: "90%" }} >
                              <h5 className=" text-light font-bold " style={{ minWidth: "150px" }}>
                                EVENT NAME :
                              </h5>
                              <p className="text-light"><span style={{ textTransform: "uppercase" }}>{eventData.event_name}</span></p>
                            </div>

                            <div className="d-flex align-items-center mb-3 " style={{ width: "90%" }} >
                              <h5 className=" text-light font-bold "
                                style={{ minWidth: "150px" }}  >
                                START :
                              </h5>
                              <p className="text-light">
                                {String(
                                  new Date(eventData.event_start_time).getDate()
                                ).padStart(2, "0")}
                                -
                                <span style={{ textTransform: "uppercase" }}>
                                  {
                                    month[
                                    new Date(eventData.event_start_time).getMonth()
                                    ]
                                  }
                                </span>
                                -{new Date(eventData.event_start_time).getFullYear()}
                              </p>
                            </div>

                            <div className=" detailsStartEnd mt-1 mb-3"
                              style={{ width: "100%" }} >
                              <div className="d-flex align-items-center "
                                style={{ minWidth: "250px" }} >
                                <h5 className=" text-light font-bold "
                                  style={{ minWidth: "150px" }} >
                                  {" "}
                                  GATE OPENS :
                                </h5>
                                <p className="text-light">
                                  {convertToLocalTime(eventData.event_start_time)}
                                </p>
                              </div>
                            </div>

                            <div className=" detailsStartEnd mb-3"
                              style={{ width: "100%" }} >
                              <div className="d-flex align-items-center "
                                style={{ minWidth: "250px" }} >
                                <h5 className=" text-light font-bold "
                                  style={{ minWidth: "150px" }} >
                                  END :
                                </h5>

                                <p className="text-light">
                                  {String(
                                    new Date(eventData.event_end_time).getDate()
                                  ).padStart(2, "0")}
                                  -
                                  <span style={{ textTransform: "uppercase" }}>
                                    {
                                      month[
                                      new Date(
                                        eventData.event_start_time
                                      ).getMonth()
                                      ]
                                    }
                                  </span>
                                  -
                                  {new Date(eventData.event_start_time).getFullYear()}
                                </p>
                              </div>
                            </div>

                            <div className=" detailsStartEnd mb-3"
                              style={{ width: "100%" }} >
                              <div className="d-flex align-items-center "
                                style={{ minWidth: "250px" }} >
                                <h5 className=" text-light font-bold "
                                  style={{ minWidth: "150px" }} >
                                  {" "}
                                  GATE CLOSES :
                                </h5>

                                <p className="text-light">
                                  {convertToLocalTime(eventData.event_end_time)}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center mb-3 "
                              style={{ width: "90%" }}  >
                              <h5 className=" text-light font-bold "
                                style={{ width: "150px" }} >
                                ARTISTS :
                              </h5>

                              <div className="flex flex-wrap align-items-center">
                                {eventData.artist_lineup?.map((artist, index) => (
                                  <div onClick={() =>
                                    navigate(
                                      `/singleartist/${artist.id}/${artist.name}`,
                                      {}
                                    )
                                  }
                                    key={index}
                                    className=" p-2 border rounded me-2 cursor-pointer" >
                                    <p className="text-warning font-semibold">
                                      {artist.name}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="d-flex align-items-center mb-3 "
                              style={{ width: "100%" }} >
                              <h5 className=" text-light font-bold"
                                style={{ minWidth: "150px" }} >
                                MUSIC CATEGORY :
                              </h5>
                              <p className="text-light">{eventData.music_category}</p>
                            </div>

                            <div className="d-flex align-items-center mb-3 ">
                              <h5 className=" text-light font-bold"
                                style={{ minWidth: "150px" }} >
                                EVENT CATEGORY :
                              </h5>
                              <p className="text-light">{eventData.event_category}</p>
                            </div>

                            {role !== "outlet" && (
                              <div className="d-flex align-items-center mb-3 "
                                style={{ minWidth: "90%" }}  >
                                <h5 className=" text-light font-bold"
                                  style={{ minWidth: "150px" }} >
                                  GUESTLIST :
                                </h5>

                                {!isEventReserved ? (
                                  <div>
                                    {new Date(eventData.event_start_time) >
                                      new Date() ? (
                                      <button onClick={handleReservation}
                                        className="bg-transparent text-warning font-semibold hover:text-white py-2 px-2 border hover:border-transparent rounded">
                                        RESERVATION
                                      </button>
                                    ) : (
                                      <button className="bg-transparent text-warning  font-semibold hover:text-white py-2 px-2 border hover:border-transparent rounded">
                                        CLOSED
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <button
                                    className="bg-transparent text-warning  font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded"
                                    style={{ minWidth: "150px" }}
                                  >
                                    RESERVED
                                  </button>
                                )}
                              </div>
                            )}

                            {role !== "outlet" && (
                              <div className="d-flex align-items-center mb-3 "
                                style={{ minWidth: "90%" }}  >
                                <h5 className=" text-light font-bold"
                                  style={{ minWidth: "150px" }} >
                                  TICKETS :
                                </h5>

                                {new Date(eventData.event_start_time) > new Date() ? (
                                  <button onClick={handleTicketBook}
                                    className="bg-transparent text-warning   font-semibold py-2 px-2 border  hover:border-transparent rounded"
                                  >
                                    BOOK NOW
                                  </button>
                                ) : (
                                  <button className="bg-transparent text-warning font-semibold py-2 px-2 border  hover:border-transparent rounded">
                                    CLOSED
                                  </button>
                                )}
                              </div>
                            )}

                            <div className="d-flex align-items-center mb-3 "
                              style={{ width: "90%" }} >
                              <h5 className=" text-light font-bold"
                                style={{ minWidth: "150px" }} >
                                COVER :
                              </h5>
                              <p className="text-light ">
                                <span>
                                  <CurrencyRupeeIcon />
                                </span>

                                {eventData.event_price}
                              </p>
                            </div>

                            <div className=" flex align-items-center"
                              style={{ width: "100%" }} >
                              <div className="d-flex align-items-center ">
                                <h5 className=" text-light font-bold"
                                  style={{ minWidth: "150px" }}  >
                                  VENUE :
                                </h5>
                                <div onClick={() =>
                                  navigate(
                                    `/singleoutlet/${eventData.outlet_id
                                    }/${eventData.outlet_name.replace(/ /g, "_")}`
                                  )
                                }
                                  className=" py-2 px-2 border rounded me-2 cursor-pointer " >
                                  <p className="text-warning font-semibold">
                                    {eventData.outlet_name}
                                  </p>
                                </div>

                                {eventData?.event_location_map !== undefined && (
                                  <a href={eventData?.event_location_map}
                                    target="_blank"
                                    rel="noreferrer" >
                                    <LocationOnIcon
                                      style={{ color: "white", fontSize: "30" }} />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12  col-sm-12 ">
                  <div className="row ">
                    <div className="card blocks-content mt-10 ">
                      <div className="card-body p-0">
                        <h5 className="card-title text-center text-light mb-3 font-semibold"
                          style={{ fontSize: "17px" }}  >
                          GALLERY
                        </h5>

                        <p className="card-text text-light mb-2" >
                          {eventData.outlet_id === id && (
                            <div
                              onClick={() =>
                                navigate(
                                  `/gallery/event/${eventData.event_id}/${eventData.event_name}`
                                )
                              }
                              className="d-flex justify-content-start align-items-center mt-3 ">
                              <p className="text-light font-bold cursor-pointer">
                                ADD TO ALBUM
                              </p>
                            </div>
                          )}

                          <div
                            onClick={() =>
                              navigate(
                                `/images/events/${eventData.event_id}/${eventData.event_name}`
                              )
                            }
                            className="d-flex justify-content-start align-items-center mt-3 cursor-pointer">
                            <p className="text-light font-bold">IMAGES</p>
                            <CollectionsIcon className="ms-3" style={{ color: "white" }} />
                          </div>
                          <div
                            onClick={() =>
                              navigate(
                                `/videos/event_videos/${eventData.event_id}/${eventData.event_name}`
                              )
                            }
                            className="d-flex justify-content-start align-items-center mt-3 cursor-pointer" >
                            <p className=" text-light font-bold">VIDEOS</p>
                            <CastIcon className="ms-3" style={{ color: "white", fontWeight: "600" }} />
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="mt-3  card blocks-content">
                      <div className="card-body p-0">
                        <h5 className="card-title text-center text-light mb-3 font-semibold"
                          style={{ fontSize: "17px" }}  >
                          DESCRIPTION
                        </h5>

                        <p className="card-text text-light mb-2" >
                          {eventData.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-3 blocks-content">
                  <div >
                    <div className="videosectiondisclaimer2 mt-3 ">
                      <div className=" " >
                        <div className=" p-0">
                          <h5 className="card-title  text-light mb-3 font-semibold"
                            style={{ fontSize: "17px" }} >
                            RULES &nbsp;APPLICABLE:
                          </h5>

                          <p className="card-text text-light mb-2"
                            style={{ whiteSpace: "pre" }} >
                            {eventData.event_disclaimer}
                          </p>
                        </div>
                      </div>

                      <RWebShare data={{
                        text: "Web Share - Nerolife",
                        url: window.location.href,
                        title: "Nerolife - Event",
                      }}>
                        <div className="sharebutton cursor-pointer">
                          <h1 className="me-1">Share</h1>
                          <IoIosShareAlt style={{ fontSize: "24px" }} />
                        </div>
                      </RWebShare>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1
            className="ms-2 mb-2 font-semibold"
            style={{ fontSize: "17px", textTransform: "uppercase" }}
          >
            {eventData.event_name}
          </h1>

          <div className="flex justify-content-between">
            <div style={{ height: "100px", width: "25%" }}>
              <img
                src={`${BASE_URL}${eventData?.poster_url}`}
                alt="event img"
                className="outletaccount"
                style={{
                  objectFit: "fill",
                  height: "100px",
                  width: "100px",
                }}
              />
            </div>

            <div
              className="border rounded p-2"
              style={{ height: "100px", width: "75%", overflow: "auto" }}
            >
              <p style={{ textAlign: "justify" }}>{eventData.description}</p>
            </div>
          </div>

          <div className="ms-2 mt-4">
            <label
              style={{ color: "white", fontSize: "17px" }}
              htmlFor="exampleFormControlInput4"
              className="form-label mb-2 text-dark font-semibold" >
              RESERVATION CATEGORY
            </label>
            <div className="row px-3">
              <div className="col   p-2" style={{ height: "50px" }}>
                <div className="form-check ">
                  <input
                    className="form-check-input text-dark"
                    type="radio"
                    name="customer_category"
                    id="gridRadios1"
                    value="LADIES"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label text-dark"
                    htmlFor="gridRadios1"
                  >
                    LADIES
                  </label>
                </div>
              </div>
              <div className="col  p-2 " style={{ height: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="customer_category"
                    id="gridRadios2"
                    value="COUPLE"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label text-dark"
                    htmlFor="gridRadios2"
                  >
                    COUPLE
                  </label>
                </div>
              </div>

              <div className="col  p-2 " style={{ height: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="customer_category"
                    id="gridRadios3"
                    value="GROUP"
                    onChange={handleChange}
                    defaultChecked
                  />
                  <label
                    className="form-check-label text-dark"
                    htmlFor="gridRadios3"
                  >
                    GROUP
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" flex ms-2"
            style={{ height: "100px", margin: "auto" }}
          >
            <div className="p-1" style={{ width: "65%" }}>
              <h1 className="font-semibold mb-3" style={{ fontSize: "17px" }}>
                RESERVE GUESTLIST
              </h1>
              <p style={{ fontSize: "12px" }}>1 Lady is Counted as 1 Member </p>
              <p style={{ fontSize: "12px" }}>
                1 Couple is Counted as 2 Members{" "}
              </p>
              <p style={{ fontSize: "12px" }}>
                1 Group is Counted as 4 Members{" "}
              </p>
            </div>
            <div
              className="border flex justify-content-center align-items-center"
              style={{ width: "35%" }}
            >
              <div>
                <ButtonGroup
                  variant="contained"
                  ref={anchorRef}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick}>
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={
                      openGuestList ? "split-button-menu" : undefined
                    }
                    aria-expanded={openGuestList ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={openGuestList}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseGuestList}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>

          <div className=" flex justify-content-center mt-3">
            <Button
              onClick={reserveEvent}
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={loading}
        onClose={handleloadingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="flex align-items-center" sx={style}>
          <CircularProgress />

          <div>
            <p className="ms-2" style={{ fontSize: "15px" }}>
              Reservation in Progress ...
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Singleevent;
